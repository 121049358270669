<template>
  <b-container fluid>
    <CRow>
      <!-- {{ role_akses ? role_akses.length : null }} {{ master_akses.length }} {{ bulk_pool_cms.length }} -->
      <CCol>
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h2 class="h4 page-title">
              <b>Manajemen Akses</b>
            </h2>
          </div>
        </div>
        <CRow class="mt-4 mb-4">
          <CCol col="6">
            <label for="selectLokasi" class="mb-2"> Jenis Tenaga Medis</label>
            <Multiselect
              id="selectroles"
              @select="set_role($event)"
              @remove="remove_role($event)"
              :options="list_roles"
              v-model="selected_roles"
              label="labels"
              selectLabel="Enter to Select"
              selectedLabel="Dipilih"
              placeholder="Select Roles"
            />
          </CCol>
          <CCol col="6">
            <CRow class="mt-4 mx-2">
              <CCol col="3" class="mt-1 py-1"><CButton color="success" :disabled="busy" block @click="save_akses_bulk()"> Save</CButton> </CCol>
              <CCol col="3" class="mt-1 py-1"><CButton color="warning" :disabled="busy" block @click="remove_role()"> Remove All</CButton> </CCol>
              <CCol col="3" class="mt-1 py-1"><CButton v-if="$store.state.real_role == 'super admin'" color="primary" :disabled="busy" block @click="checkAll()"> Check All</CButton> </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="menu-box">
          <CCol col="8" class="menu-content h-100">
            <div class="menu-scrollable" v-if="selected_roles">
              <h5 class="mb-0 mt-4">Menu</h5>
              <!-- <small class="pb-2" :class="data_changed ? 'text-danger' : 'text-success'" hidden>Tidak ada perubahan...</small> -->
              <div class="d-flex flex-column mt-2" style="gap: 10px">
                <!-- Level 1 -->
                <div v-for="menu in menus" :key="menu.id">
                  <div role="group" class="form-check form-check-inline">
                    <span @click="remove_akses(menu)">
                      <input :id="menu.id" type="checkbox" class="form-check-input" v-model="menu.checked" :checked="role_akses.includes(menu.kode_akses)" />
                      <label :for="menu.id" class="form-check-label">
                        {{ menu.nama_akses }}
                      </label>
                    </span>
                    <span>
                      <div class="ml-2" @click="showFunction(menu)" v-if="menu.tipe == 'menu'">
                        <CIcon name="cil-check" />
                      </div>
                    </span>
                  </div>
                  <div v-if="menu.submenu && menu.checked" class="sub1">
                    <div v-for="sub1 in menu.submenu" :key="sub1.kode_akses">
                      <div role="group" class="form-check form-check-inline">
                        <span @click="remove_akses(sub1)">
                          <input :id="sub1.id" type="checkbox" class="form-check-input" v-model="sub1.checked" :checked="role_akses.includes(sub1.kode_akses)" />
                          <label :for="sub1.id" class="form-check-label">
                            {{ sub1.nama_akses }}
                          </label>
                        </span>
                        <!-- <span>
                          <div
                            class="ml-2"
                            @click="showFunction(sub1)"
                            v-if="
                              sub1.tipe == 'level 1' && sub1.sub.length == 0
                            "
                          >
                            <CIcon name="cil-cog" />
                          </div>
                          <div class="ml-2" @click="showChildren(sub1)" v-else>
                            <CIcon name="cil-check" />
                          </div>
                        </span> -->
                      </div>
                      <!-- <div v-if="sub1.sub && sub1.checked" class="sub2">
                        <div v-for="sub2 in sub1.sub" :key="sub2.kode_akses">
                          <div
                            role="group"
                            class="form-check form-check-inline"
                          >
                            <span @click="remove_akses(sub2.kode_akses)">
                              <input
                                :id="sub2.id"
                                type="checkbox"
                                class="form-check-input"
                                v-model="sub2.checked"
                                :checked="role_akses.includes(sub2.kode_akses)"
                              />
                              <label :for="sub2.id" class="form-check-label">
                                {{ sub2.nama_akses }}
                              </label>
                            </span>
                            <span>
                              <div
                                class="ml-2"
                                @click="showFunction(sub2)"
                                v-if="sub2.tipe == 'level 2'"
                              >
                                <CIcon name="cil-cog" />
                              </div>
                            </span>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CCol>
          <CCol col="4">
            {{ bulk_pool_cms.length }}
            <!-- {{ bulk_pool_cms }} -->
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </b-container>
</template>

<script>
export default {
  name: "Manage_Akses",
  components: {},
  data() {
    return {
      list_roles: [],
      selected_roles: null,
      role_akses: [],
      list_menus: [],
      checked: [],
      list_function: [],
      busy: false,
      function_head: null,
      log: [],
      master_akses: [],
    };
  },
  computed: {
    fetch_data() {
      let vm = this;
      return {
        halaman: vm.currentPage - 1,
        jumlah: 100,
        lokasi_id: vm.lokasi,
        nama_roles: vm.nama_roles,
        kode_roles: vm.kode_roles,
      };
    },
    rakses() {
      return this.role_akses;
    },
    bulk_pool_cms() {
      let bulk = [];
      this.master_akses.forEach((ele) => {
        this.role_akses.forEach((ele1) => {
          if (ele1 == ele.kode_akses) {
            bulk.push({
              ms_role_id: this.selected_roles.ms_role_id,
              ms_akses_id: ele.ms_akses_id,
              puskesmas_id: this.$store.state.puskesmas_id ? this.$store.state.puskesmas_id : null,
            });
          }
        });
      });
      return bulk;
    },
    is_admin() {
      return this.$store.state.real_role ? this.$store.state.real_role == "super admin" : false;
    },
    // funcs() {
    //   let acc = this.role_akses;
    //   if (this.rakses != []) {
    //     return this.list_function.map((item) => {
    //       if (acc.includes(item.kode_akses)) {
    //         item.checked = true;
    //       } else {
    //         item.checked = false;
    //       }
    //       return item;
    //     });
    //   } else {
    //     return this.list_function;
    //   }
    // },
    menus() {
      if (this.rakses != []) {
        let acc = this.role_akses;
        let x = this.list_menus.map((item) => {
          if (item.submenu) {
            item.submenu.map((item1) => {
              if (acc.includes(item1.kode_akses)) {
                item1.checked = true;
              } else {
                item1.checked = false;
              }
              return item1;
            });
          }
          if (acc.includes(item.kode_akses)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        });
        return x;
      } else {
        return this.list_menus;
      }
    },
    acc() {
      return this.$store.state.all_akses ? this.$store.state.all_akses : null;
    },
  },
  mounted() {
    this.get_master_akses();
    this.get_akses();
    this.get_data();
  },
  methods: {
    async set_role(roles) {
      let vm = this;
      if (roles.nama_role) {
        vm.role_akses = [];
        vm.list_function = [];
        let role_akses = await vm.$axios.post("pool_akses/list_kode_akses", {
          nama_role: roles.nama_role,
        });
        // console.log(role_akses.data.data, "kode");
        vm.role_akses = role_akses.data.data ? role_akses.data.data : [];
      } else {
        vm.role_akses = [];
      }
    },
    checkAll() {
      this.list_menus.map((item) => {
        if (item.submenu) {
          item.submenu.map((item1) => {
            this.remove_akses2(item1);
            item1.checked = true;
            return item1;
          });
        }
        this.remove_akses2(item);
        item.checked = true;
        return item;
      });
    },
    async save_akses_bulk() {
      let vm = this;
      let bulk = await vm.$axios.post("pool_akses/registerBulk", {
        bulk_pool_akses: vm.bulk_pool_cms,
      });
      // console.log(bulk.data);
      if (bulk.data.status == 200) {
        if (bulk.data.message) {
          vm.busy = false;
          vm.$store.commit("set_alert", {
            variant: "success",
            msg: "BERHASIL MENDAFTARKAN ROLE AKSES",
            showing: true,
          });
        } else {
          vm.busy = false;
          vm.$store.commit("set_alert", {
            variant: "danger",
            msg: bulk.data.message.toUpperCase(),
            showing: true,
          });
        }
      } else {
        vm.busy = false;
        vm.$store.commit("set_alert", {
          variant: "danger",
          msg: bulk.data.message.toUpperCase(),
          showing: true,
        });
      }
    },
    async get_data() {
      let vm = this;
      let roles = await vm.$axios.post("ms_role/list");
      // console.log(roles.data.data, "roles");
      if (roles.data.status == 200) {
        if (roles.data.message == "sukses") {
          if (roles.data.data.length) {
            vm.list_roles = roles.data.data.filter((item) => {
              item.labels = item.nama_role.toUpperCase();
              return !vm.is_admin ? item.nama_role != "super admin" && item.nama_role != "dinkes" : item;
            });
            vm.loading = false;
          } else {
            vm.loading = false;
          }
        } else {
          vm.loading = false;
        }
      } else {
        vm.loading = false;
      }
    },
    async get_master_akses() {
      let vm = this;
      let master = await vm.$axios.post("ms_akses/list");
      // console.log(master.data.data, "master");
      if (master.data.status == 200) {
        if (master.data.message == "sukses") {
          if (master.data.data.length) {
            vm.master_akses = master.data.data.map((item) => {
              return item;
            });
            vm.loading = false;
          } else {
            vm.loading = false;
          }
        } else {
          vm.loading = false;
        }
      } else {
        vm.loading = false;
      }
    },
    // async showFunction(head) {
    //   let vm = this;
    //   vm.function_head = head.nama_akses;
    //   let func = await vm.$axios.post("masterAkses/list", {
    //     head: head.kode_akses,
    //   });
    //   // console.log(func);
    //   if (func.data.status == 200) {
    //     vm.list_function = func.data.data.map((item) => {
    //       item.checked = false;
    //       return item;
    //     });
    //   } else {
    //     vm.list_function = [];
    //   }
    // },
    remove_role() {
      this.role_akses = [];
    },
    remove_akses(kode) {
      let vm = this;
      if (!vm.role_akses.includes(kode.kode_akses)) {
        vm.role_akses.push(kode.kode_akses);
        // vm.log.push({ menu: kode.nama_akses, status: "enable" });
      } else {
        let index = vm.role_akses.indexOf(kode.kode_akses);
        if (index !== -1) {
          vm.role_akses.splice(index, 1);
          // vm.log.push({ menu: kode.nama_akses, status: "disable" });
        }
      }
    },
    remove_akses2(kode) {
      let vm = this;
      if (!vm.role_akses.includes(kode.kode_akses)) {
        vm.role_akses.push(kode.kode_akses);
        // vm.log.push({ menu: kode.nama_akses, status: "enable" });
      }
    },
    // async remove_function(data) {
    //   let vm = this;
    //   if (
    //     vm.acc.includes("STT_CMS_AM_UPT") &&
    //     vm.selected_roles.kode_role != "super"
    //   ) {
    //     vm.busy = true;
    //     let akses;
    //     let message = "";
    //     if (!vm.role_akses.includes(data.kode_akses)) {
    //       message = "Add Permission Success";
    //       vm.role_akses.push(data.kode_akses);
    //       akses = await vm.$axios.post("pool_akses/register", {
    //         role_id: vm.selected_roles.role_id,
    //         ms_akses_id: data.id,
    //       });
    //     } else {
    //       message = "Remove Permission Success";
    //       let index = vm.role_akses.indexOf(data.kode_akses);
    //       if (index !== -1) {
    //         vm.role_akses.splice(index, 1);
    //       }
    //       akses = await vm.$axios.post("pool_akses/deleteByRoleAksesId", {
    //         role_id: vm.selected_roles.role_id,
    //         ms_akses_id: data.id,
    //       });
    //     }
    //     console.log(akses.data.status);
    //     if (akses.data.status == 200) {
    //       if (akses.data.message == "sukses") {
    //         vm.busy = false;
    //         vm.$store.commit("set_alert", {
    //           variant: "success",
    //           msg: message,
    //           showing: true,
    //         });
    //       } else {
    //         vm.busy = false;
    //         vm.$store.commit("set_alert", {
    //           variant: "warning",
    //           msg: akses.data.message,
    //           showing: true,
    //         });
    //       }
    //     } else {
    //       vm.busy = false;
    //       vm.$store.commit("set_alert", {
    //         variant: "warning",
    //         msg: akses.data.message,
    //         showing: true,
    //       });
    //     }
    //   }
    // },
    async get_akses() {
      let vm = this;
      let menus = await vm.$axios.post("ms_akses/list_head_menu");
      // console.log(menus.data.data, "menus");
      if (menus.data.status == 200) {
        if (menus.data.message == "sukses") {
          if (menus.data.data.length) {
            vm.list_menus = menus.data.data.map((item) => {
              item.checked = false;
              return item;
            });
            vm.loading = false;
          } else {
            vm.loading = false;
          }
        } else {
          vm.loading = false;
        }
      } else {
        vm.loading = false;
      }
    },
    alert(event) {
      // console.log("alert", event);
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.get_data();
    },
  },
};
</script>
<style scoped>
.inside {
  background-color: grey;
}
.sub1 {
  margin: 0.25rem 1.25rem;
}
.sub1 .sub2 {
  margin: 0.25rem 1.25rem;
}

.form-check-label {
  margin-top: 0;
  cursor: pointer;
}

.menu-box {
  flex-grow: 1;
  position: relative;
  width: 100%;
  /* max-height: 1000px; */
}

.menu-content {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  max-height: 800px;
  /* position: absolute; */
}
</style>
